import RememberSearchConfig from "@/core/config/RememberSearchConfig";
import GlobalConfig from "@/core/config/GlobalConfig";
import { Actions, Mutations } from "@/store/enums/RememberSearchEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

@Module
export default class stores extends VuexModule {
  searchData: Object = RememberSearchConfig

  get getPerPage() {
    const rememberSearchData = this.searchData;
    return function (module: string) {
      if (typeof rememberSearchData[module] != "undefined") {
        return (rememberSearchData[module]["perPage"]) ? rememberSearchData[module]["perPage"] : "";
      }
    };
  }

  get getPageNumber() {
    const rememberSearchData = this.searchData;
    return function (module: string) {
      if (typeof rememberSearchData[module] != "undefined") {
        return (rememberSearchData[module]["pageNumber"]) ? rememberSearchData[module]["pageNumber"] : "";
      }
    };
  }

  get getPageSize() {
    const rememberSearchData = this.searchData;
    return function (module: string) {
      if (typeof rememberSearchData[module] != "undefined") {
        return (rememberSearchData[module]["pageSize"]) ? rememberSearchData[module]["pageSize"] : "";
      }
    };
  }

  get getSearch() {
    const rememberSearchData = this.searchData;
    return function (module: string) {
      if (typeof rememberSearchData[module] != "undefined") {
        return (rememberSearchData[module]["search"]) ? rememberSearchData[module]["search"] : "";
      }
    };
  }

  get getAssetSearch() {
    const rememberSearchData = this.searchData;
    return function (module: string) {
      if (typeof rememberSearchData[module] != "undefined") {
        return (rememberSearchData[module]["search"]) ? rememberSearchData[module]["search"] : "";
      }
    };
  }

  get getCommentSearch() {
    const rememberSearchData = this.searchData;
    return function (module: string) {
      if (typeof rememberSearchData[module] != "undefined") {
        return (rememberSearchData[module]["search"]) ? rememberSearchData[module]["search"] : "";
      }
    };
  }

  get getOrderSearch() {
    const rememberSearchData = this.searchData;
    return function (module: string) {
      if (typeof rememberSearchData[module] != "undefined") {
        return (rememberSearchData[module]["search"]) ? rememberSearchData[module]["search"] : "";
      }
    };
  }

  //Common clear search mutation
  @Mutation
  [Mutations.CLEAR_SEARCH](module: string) {
    if (typeof module != "undefined" && (typeof this.searchData[module] != "undefined")) {
      const rememberModule = this.searchData[module];
      rememberModule["pageNumber"] = GlobalConfig.paginationConfig.pageNumber;
      if (module == "orders") {
        rememberModule["search"] = {
          "search": "",
          "searchByUser": null,
          "searchByOrderProductStatus": null,
          "orderPredefinedDate": null,
          "searchByOrderDate": null,
          "searchByProductionDueDate": null,
        };
      } else if (module == "assets") {
        rememberModule["search"] = {
          "search": "",
          "pricingMethod": "",
          "priceLookup": "",
          "additionalConfigLookup": "",
          "availability": ""
        };
      } else if (module == "jobs") {
        rememberModule["search"] = {
          "search": "",
          "store_id": null,
          "filter_by_order_date": null,
          "filter_by_order_due_date": null,
          "order_date_sort_order": null,
          "order_due_date_sort_order": null
        };
      } else if (module == "predefinedComments") {
        rememberModule["search"] = {
          "search": "",
          "user_id": ""
        };
      }
      else {
        rememberModule["search"] = "";
      }
    }
  }

  //Common per page mutation
  @Mutation
  [Mutations.SET_DEFAULT_PER_PAGE](per_page: number) {
    if (typeof per_page != "undefined") {
      Object.keys(this.searchData).forEach(i => {
        if (this.searchData[i]["perPage"]) {
          this.searchData[i]["perPage"] = Number(per_page);
        }
      });
    }
  }

  //Common per page mutation
  @Mutation
  [Mutations.SET_PER_PAGE](params: object) {
    if (typeof params["module"] != "undefined" && typeof params["value"] != "undefined") {
      this.searchData[params["module"]]["perPage"] = params["value"];
    }
  }

  //Common page number mutation
  @Mutation
  [Mutations.SET_PAGE_NUMBER](params: object) {
    if (typeof params["module"] != "undefined" && typeof params["value"] != "undefined") {
      this.searchData[params["module"]]["pageNumber"] = params["value"];
    }
  }

  //Common search mutation
  @Mutation
  [Mutations.SET_SEARCH](params: object) {
    if (typeof params["module"] != "undefined" && typeof params["value"] != "undefined") {
      this.searchData[params["module"]]["search"] = params["value"];
    }
  }

  //Asset module search mutation
  @Mutation
  [Mutations.SET_ASSET_SEARCH](params: object) {
    if (typeof params["module"] != "undefined" && typeof params["params"] != "undefined") {
      this.searchData[params["module"]]["search"] = params["params"];
    }
  }

  //Asset module search mutation
  @Mutation
  [Mutations.SET_COMMENT_SEARCH](params: object) {
    if (typeof params["module"] != "undefined" && typeof params["params"] != "undefined") {
      this.searchData[params["module"]]["search"] = params["params"];
    }
  }

  //Order module search mutation
  @Mutation
  [Mutations.SET_ORDER_SEARCH](params: object) {
    if (typeof params["module"] != "undefined" && typeof params["params"] != "undefined") {
      this.searchData[params["module"]]["search"] = params["params"];
    }
  }

  //Common pagination per page action
  @Action
  [Actions.PER_PAGE](params: object) {
    this.context.commit(Mutations.SET_PER_PAGE, params);
  }

  //Common pagination page number action
  @Action
  [Actions.PAGE_NUMBER](params: object) {
    this.context.commit(Mutations.SET_PAGE_NUMBER, params);
  }

  //Common search action
  @Action
  [Actions.SEARCH](params: object) {
    this.context.commit(Mutations.SET_SEARCH, params);
  }

  //Order module search action
  @Action
  [Actions.ORDER_SEARCH](params: object) {
    this.context.commit(Mutations.SET_ORDER_SEARCH, params);
  }

  //Asset module search action
  @Action
  [Actions.ASSET_SEARCH](params: object) {
    this.context.commit(Mutations.SET_ASSET_SEARCH, params);
  }

  //Asset module search action
  @Action
  [Actions.COMMENT_SEARCH](params: object) {
    this.context.commit(Mutations.SET_COMMENT_SEARCH, params);
  }
}