import Layout2 from "@/layout/Layout.vue";

export default [{
  path: "/asset-types",
  component: Layout2,
  children: [{
    path: "/asset-types",
    name: "asset-types-list",
    component: () => import("@/views/asset-types/List.vue"),
    meta: {
      auth: true,
      section: "assets",
      allowedPermission: ["readAsset"],
      module: "assetTypes"
    }
  }, {
    path: "add",
    name: "add-asset-type",
    component: () => import("@/views/asset-types/AddEditAssetType.vue"),
    meta: {
      auth: true,
      section: "assets",
      allowedPermission: ["createUpdateAsset"],
      module: "assetTypes"
    }
  }, {
    path: "edit/:id",
    name: "edit-asset-type",
    component: () => import("@/views/asset-types/AddEditAssetType.vue"),
    meta: {
      auth: true,
      section: "assets",
      allowedPermission: ["updateAsset", "createUpdateAsset"],
      module: "assetTypes"
    }
  }
  ]
}];