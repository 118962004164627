const DocMenuConfig = [
  {
    pages: [
      {
        heading: "DASHBOARD",
        route: "/dashboard",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-app-indicator",
      },
    ],
  },
  {
    pages: [
      {
        sectionTitle: "QUOTES",
        sectionKey: "quotes",
        fontIcon: "bi-chat",
        sub: [
          {
            heading: "QUOTES",
            route: "/quotes",
            fontIcon: "bi-app-indicator",
            allowedPermission: ["readQuote"],
          },
          {
            heading: "CUSTOM_QUOTES",
            route: "/custom-quote",
            fontIcon: "bi-app-indicator",
            allowedPermission: ["createUpdateQuote"],
          }
        ],
      }
    ],
  },
  {
    pages: [
      {
        sectionTitle: "JOBS",
        sectionKey: "jobs",
        fontIcon: "bi-cart bi fs-3",
        sub: [
          {
            heading: "JOBS",
            route: "/jobs",
            fontIcon: "bi-app-indicator",
            allowedPermission: ["readJob"],
          },
          {
            heading: "My Jobs",
            route: "/my-jobs",
            fontIcon: "bi-app-indicator",
            allowedPermission: ["myJobs", "allDevices"],
          }
        ],
      },
    ],
  },
  {
    pages: [
      {
        sectionTitle: "ORDERS",
        sectionKey: "orders",
        fontIcon: "bi-cart bi fs-3",
        sub: [
          {
            heading: "ORDERS",
            route: "/orders",
            fontIcon: "bi-app-indicator",
            allowedPermission: ["readOrder"],
          },
/*             {
            heading: "ORDER_PRODUCTS",
            route: "/order-products",
            fontIcon: "bi-app-indicator",
            allowedPermission: "readOrder",
          }, */
          {
            heading: "ARCHIEVE_ORDERS",
            route: "/archive-orders",
            fontIcon: "bi-app-indicator",
            allowedPermission: ["readOrder"],
          },
          // {
          //   heading: "JOB_BOARD",
          //   route: "/job-board",
          //   fontIcon: "bi-app-indicator",
          //   allowedPermission: "readOrder",
          // }
        ],
      }
    ],
  },
  {
    pages: [
      {
        sectionTitle: "ASSETS",
        sectionKey: "assets",
        fontIcon: "bi-tags",
        sub: [
          {
            heading: "ASSETS",
            route: "/assets",
            fontIcon: "bi-app-indicator",
            allowedPermission: ["readAsset"],
          },
          {
            heading: "ASSET_RULES",
            route: "/asset-rules",
            fontIcon: "bi-app-indicator",
            allowedPermission: ["readRule"],
          },
          {
            heading: "PRICE_GROUPS",
            route: "/asset-price-groups",
            fontIcon: "bi-app-indicator",
            allowedPermission: ["readAsset"],
          }
        ],
      }
    ],
  },
  {
    pages: [
      {
        heading: "DEVICES",
        route: "/my-devices",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi bi-pc-display-horizontal",
        allowedPermission: ["myDevices", "allDevices"]
      },
    ],
  },
  {
    pages: [
      {
        heading: "CUSTOMERS",
        route: "/customers",
        fontIcon: "bi-people-fill",
        allowedPermission: ["readCustomer"],
      },
    ],
  },
  {
    pages: [
      {
        sectionTitle: "USERS",
        sectionKey: "admin",
        fontIcon: "bi-person-circle",
        sub: [
          {
            heading: "USERS",
            route: "/users",
            fontIcon: "bi-app-indicator",
            allowedPermission: ["readUser"]
          },
          {
            heading: "ROLES_MANAGEMENT",
            route: "/roles",
            fontIcon: "bi-app-indicator",
            allowedPermission: ["readRole"]
          },
        ],
      }
    ],
  },
  {
    pages: [
      {
        heading: "STORES",
        route: "/stores",
        allowedPermission: ["readStore"],
        fontIcon: "bi bi-shop",
      },
    ],
  },
  {
    pages: [
      {
        heading: "TEMPLATE_MANAGEMENT",
        route: "/template-management",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-file-earmark-pdf bi fs-3",
        allowedPermission: ["readTemplate"]
      },
    ],
  },
  {
    pages: [
      {
        heading: "SHIPPING_METHOD",
        route: "/shipping-method",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi bi-truck",
        allowedPermission: ["readShippingMethod"]
      },
    ],
  },
  {
    pages: [
      {
        heading: "MASTER_PACKAGES",
        route: "/master-package",
        svgIcon: "media/icons/duotune/art/art002.svg",
        fontIcon: "bi-box2",
        allowedPermission: ["readMasterPackage"]
      },
    ],
  },
  {
    pages: [
      {
        sectionTitle: "REPORTS",
        sectionKey: "reports",
        fontIcon: "bi-graph-up-arrow",
        sub: [
          {
            heading: "AUDIT_LOGS",
            route: "/audit-logs",
            fontIcon: "bi-person",
            allowedPermission: ["readAuditLog"]
          },
          {
            heading: "ORDER_REPORT",
            route: "/order-report",
            fontIcon: "bi-app-indicator",
            allowedPermission: ["readOrder"],
          }
        ],
      }
    ],
  },
  {
    pages: [
      {
        sectionTitle: "SETTINGS",
        sectionKey: "settings",
        fontIcon: "bi-gear",
        sub: [
          {
            heading: "OPERATIONS",
            route: "/operations",
            fontIcon: "bi-person",
            allowedPermission: ["readOperations"]
          },
          {
            heading: "ACTIVITY",
            route: "/activity",
            fontIcon: "bi-person",
            allowedPermission: ["readActivity"]
          },
          {
            heading: "DEVICE_MANAGEMENT",
            route: "/devices",
            fontIcon: "bi-person",
            allowedPermission: ["readDevices"]
          },
          {
            heading: "JOB_WORKFLOW",
            route: "/job-workflow",
            fontIcon: "bi-person",
            allowedPermission: ["readJobWorkflow"]
          },
          {
            heading: "STATUS",
            route: "/status",
            fontIcon: "bi-person",
            allowedPermission: ["readStatus"]
          },
          {
            heading: "PREDEFINED_COMMENTS",
            route: "/predefined-comments",
            fontIcon: "bi-person",
            allowedPermission: ["readPredefinedComments"]
          },
          {
            heading: "SHEET_MANAGEMENT",
            route: "/sheets",
            fontIcon: "bi-book",
            allowedPermission: ["readSheets"]
          },
          {
            heading: "INK_MANAGEMENT",
            route: "/inks",
            fontIcon: "bi bi-droplet-fill",
            allowedPermission: ["readInks"]
          },
          {
            heading: "SITE_ADDRESSES",
            route: "/site-addresses",
            fontIcon: "bi bi-droplet-fill",
            allowedPermission: ["readSiteAddresses"]
          },
          {
            heading: "CONFIGURATION_SETTINGS",
            route: "/configuration-settings",
            fontIcon: "bi bi-droplet-fill",
            allowedPermission: ["manageSettings"]
          }
        ],
      }
    ],
  },
];

export default DocMenuConfig;
