import Layout2 from "@/layout/Layout.vue";

export default [{
  path: "/configuration-settings",
  component: Layout2,
  children: [{
    path: "/configuration-settings",
    name: "configuration-settings",
    component: () => import("@/views/configuration-settings/List.vue"),
    meta: {
      auth: true,
      section: "settings",
      allowedPermission: ["manageSettings"],
      module: "configurationSettings"
    }
  }
  ]
}];