import Layout2 from "@/layout/Layout.vue";

export default [{
  path: "/quotes",
  component: Layout2,
  children: [{
    path: "/quotes",
    name: "quotesList",
    component: () => import("@/views/quotes/List.vue"),
    meta: {
      auth: true,
      section: "quotes",
      allowedPermission: ["readQuote"],
      module: "quotes",
    }
  }]
}, {
  path: "/quote-version/:id",
  component: Layout2,
  children: [{
    path: "/quote-version/:id",
    name: "quoteVersions",
    component: () => import("@/views/quotes/List.vue"),
    meta: {
      auth: true,
      section: "quotes",
      pageType: "quoteVersion",
      module: "quotes"
    }
  }]
}, {
  path: "/custom-quote",
  component: Layout2,
  children: [{
    path: "/custom-quote",
    name: "createQuote",
    component: () => import("@/views/quotes/AddEditCustomQuote.vue"),
    meta: {
      auth: true,
      allowedPermission: ["createUpdateQuote"],
      module: "quotes",
      pageType: "createQuote",
      section: "quotes",
    }
  }]
}, {
  path: "/custom-quote-product/edit/:id/:product_id/:is_show_price_changed?",
  component: Layout2,
  children: [{
    path: "/custom-quote-product/edit/:id/:product_id/:is_show_price_changed?",
    name: "updateQuoteProduct",
    component: () => import("@/views/quotes/AddEditCustomQuote.vue"),
    meta: {
      auth: true,
      allowedPermission: ["updateQuote", "createUpdateQuote"],
      module: "quotes",
      pageType: "updateQuote",
      section: "quotes",
    }
  }]
}, {
  path: "/custom-quote-product/add/:id",
  component: Layout2,
  children: [{
    path: "/custom-quote-product/add/:id",
    name: "addQuoteProduct",
    component: () => import("@/views/quotes/AddEditCustomQuote.vue"),
    meta: {
      auth: true,
      allowedPermission: ["updateQuote", "createUpdateQuote"],
      module: "quotes",
      pageType: "updateQuote",
      section: "quotes",
    }
  }]
}
, {
  path: "/duplicate-quote/:id",
  component: Layout2,
  children: [{
    path: "/duplicate-quote/:id",
    name: "duplicateQuote",
    component: () => import("@/views/quotes/EditQuote.vue"),
    meta: {
      auth: true,
      allowedPermission: ["duplicateQuote"],
      module: "quotes",
      pageType: "duplicateQuote",
      section: "quotes",
    }
  }]
}, {
  path: "/custom-quote/edit/:id",
  component: Layout2,
  children: [{
    path: "/custom-quote/edit/:id",
    name: "editQuote",
    component: () => import("@/views/quotes/EditQuote.vue"),
    meta: {
      auth: true,
      allowedPermission: ["updateQuote", "createUpdateQuote"],
      module: "quotes",
      pageType: "updateQuote",
      section: "quotes",
    }
  }]
}];