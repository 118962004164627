import GlobalConfig from "@/core/config/GlobalConfig";
export default {
  "users": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "stores": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "roles": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "predefinedComments": {
    "search": {
      "search": "",
      "user_id": "",
    },
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "status": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "assets": {
    "search": {
      "search": "",
      "pricingMethod": "",
      "priceLookup": "",
      "additionalConfigLookup": "",
      "availability": ""
    },
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "assetPriceGroups": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "assetRules": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "assetTypes": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "orders": {
    "search": {
      "search": "",
      "searchByUser": null,
      "searchByOrderProductStatus": null,
      "orderPredefinedDate": null,
      "searchByOrderDate": null,
      "productionDueDateOptsGroupVal": null,
      "searchByProductionDueDate": null,
    },
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "quotes": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "auditLogs": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "devices": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "operations": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "activity": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "sheets": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "inks": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "jobs": {
    "search": {
      "search": "",
      "store_id": null,
      "filter_by_order_date": null,
      "filter_by_order_due_date": null,
      "order_date_sort_order": null,
      "order_due_date_sort_order": null
    },
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "my-devices": {
    "search": "",
  },
  "template-management": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "job-workflow": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "shippingMethod": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "masterPackage": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
  "siteAddresses": {
    "search": "",
    "perPage": GlobalConfig.paginationConfig.perPage,
    "pageNumber": GlobalConfig.paginationConfig.pageNumber,
    "pageSize": GlobalConfig.paginationConfig.pageSize,
  },
};