import Layout2 from "@/layout/Layout.vue";

export default [{ 
  path: "/audit-logs",
  redirect: "/audit-logs",
  component: Layout2,
  children: [
    {
      path: "/audit-logs",
      name: "audit-logs",
      component: () => import("@/views/audit-logs/List.vue"),
      meta: {
        auth: true,
        section: "reports",
        allowedPermission: ["readAuditLog"],
        module: "auditLogs"
      },
    },
    {
      path: "/order-report",
      name: "order-report",
      component: () => import("@/views/audit-logs/OrderProductReport.vue"),
      meta: {
        auth: true,
        section: "reports",
        allowedPermission: ["readOrder"],
        module: "auditLogs"
      },
    },
  ]
}];